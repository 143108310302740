var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({ref:"bottomSheet",class:[
    'bottom-sheet',
    {
      opened: _vm.opened,
      closed: _vm.opened === false
    }
  ],style:({
    'pointer-events':
      _vm.backgroundClickable && _vm.clickToClose == false ? 'none' : 'all'
  })},_vm.handlers),[(_vm.overlay)?_c('div',{staticClass:"bottom-sheet__backdrop",style:({ background: _vm.overlayColor })}):_vm._e(),_c('div',{ref:"bottomSheetCard",class:[
      'bottom-sheet__card',
      { stripe: _vm.stripe, square: !_vm.rounded },
      _vm.effect
    ],style:([
      { bottom: _vm.cardP + 'px', maxWidth: _vm.maxWidth, maxHeight: _vm.maxHeight },
      { height: _vm.isFullScreen ? '100%' : 'auto' },
      { 'pointer-events': 'all' }
    ])},[_c('div',{ref:"pan"},[_vm._t("title")],2),_c('div',{ref:"bottomSheetCardContent",staticClass:"bottom-sheet__content",style:({ height: _vm.contentH })},[_vm._t("content")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }