<template>
  <div
    ref="bottomSheet"
    :class="[
      'bottom-sheet',
      {
        opened: opened,
        closed: opened === false
      }
    ]"
    :style="{
      'pointer-events':
        backgroundClickable && clickToClose == false ? 'none' : 'all'
    }"
    v-on="handlers"
  >
    <div
      v-if="overlay"
      class="bottom-sheet__backdrop"
      :style="{ background: overlayColor }"
    />
    <div
      ref="bottomSheetCard"
      :style="[
        { bottom: cardP + 'px', maxWidth: maxWidth, maxHeight: maxHeight },
        { height: isFullScreen ? '100%' : 'auto' },
        { 'pointer-events': 'all' }
      ]"
      :class="[
        'bottom-sheet__card',
        { stripe: stripe, square: !rounded },
        effect
      ]"
    >
      <div ref="pan">
        <slot name="title" />
        <!-- <div class="bottom-sheet__bar" /> -->
      </div>
      <div
        ref="bottomSheetCardContent"
        :style="{ height: contentH }"
        class="bottom-sheet__content"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BottomSheet',
  props: {
    overlay: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String,
      default: '640px'
    },
    maxHeight: {
      type: String,
      default: '95%'
    },
    clickToClose: {
      type: Boolean,
      default: true
    },
    effect: {
      type: String,
      default: 'fx-default'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    swipeAble: {
      type: Boolean,
      default: true
    },
    isFullScreen: {
      type: Boolean,
      default: false
    },
    overlayColor: {
      type: String,
      default: '#0000004D'
    },
    backgroundScrollable: {
      type: Boolean,
      default: false
    },
    backgroundClickable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const vm = this
    return {
      inited: false,
      opened: false,
      contentH: 'auto',
      contentScroll: 0,
      cardP: null,
      cardH: null,
      stripe: 0,
      handlers: {
        mousedown: vm.clickOnBottomSheet,
        touchstart: vm.clickOnBottomSheet
      }
    }
  },
  methods: {
    isIphone() {
      const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream
      const aspect = window.screen.width / window.screen.height
      return iPhone && aspect.toFixed(3) === '0.462'
    },
    init() {
      return new Promise(resolve => {
        this.contentH = 'auto'
        this.stripe = this.isIphone() ? 20 : 0
        this.cardH = this.$refs.bottomSheetCard.clientHeight
        this.contentH = `${this.cardH - this.$refs.pan.clientHeight}px`
        this.$refs.bottomSheetCard.style.maxHeight = this.maxHeight
        this.cardP =
          this.effect === 'fx-slide-from-right' ||
          this.effect === 'fx-slide-from-left'
            ? 0
            : -this.cardH - this.stripe
        setTimeout(() => {
          resolve()
        }, 10)
      })
    },
    open() {
      this.init().then(() => {
        this.opened = true
        this.cardP = 0
        if (!this.$props.backgroundScrollable) {
          document.body.style.overflow = 'hidden'
        }
        this.$emit('opened')
      })
    },
    close() {
      this.opened = false
      this.cardP =
        this.effect === 'fx-slide-from-right' ||
        this.effect === 'fx-slide-from-left'
          ? 0
          : -this.cardH - this.stripe
      document.body.style.overflow = ''
      this.$emit('closed')
    },
    clickOnBottomSheet(event) {
      if (this.clickToClose) {
        if (
          event.target.classList.contains('bottom-sheet__backdrop') ||
          event.target.classList.contains('bottom-sheet')
        ) {
          this.close()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-sheet {
  z-index: 99999;
  transition: all 0.4s ease;
  position: relative;

  * {
    box-sizing: border-box;
  }

  &__content {
    width: 100%;
    overflow-y: scroll;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
  }

  &__card {
    width: 100%;
    position: fixed;
    background: white;
    border-radius: 14px 14px 0 0;
    left: 50%;
    bottom: 0px;
    z-index: 9999;
    margin: 0 auto;

    &.square {
      border-radius: 15px;
    }

    &.stripe {
      padding-bottom: 20px;
    }

    &.fx-default {
      transform: translate(-50%, 0);
      transition: bottom 0.3s ease;
    }

    &.fx-fadein-scale {
      transform: translate(-50%, 0) scale(0.7);
      opacity: 0;
      transition: all 0.3s;
    }

    &.fx-slide-from-right {
      transform: translate(100%, 0);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    }

    &.fx-slide-from-left {
      transform: translate(-100%, 0);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    }
  }

  &__pan {
    padding-bottom: 20px;
    padding-top: 15px;
    height: 38px;
  }

  &__bar {
    display: block;
    width: 50px;
    height: 3px;
    border-radius: 14px;
    margin: 0 auto;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
  }

  &.closed {
    opacity: 0;
    visibility: hidden;
    .bottom-sheet__backdrop {
      animation: hide 0.3s ease;
    }
  }

  &.moving {
    .bottom-sheet__card {
      transition: none;
    }
  }

  &.opened {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .bottom-sheet__backdrop {
      animation: show 0.3s ease;
      opacity: 1;
      visibility: visible;
    }

    .bottom-sheet__card {
      &.fx-fadein-scale {
        transform: translate(-50%, 0) scale(1);
        opacity: 1;
      }

      &.fx-slide-from-right {
        transform: translate(-50%, 0);
        opacity: 1;
      }

      &.fx-slide-from-left {
        transform: translate(-50%, 0);
        opacity: 1;
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
