<template>
  <div class="productslider">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(image, index) in banners" :key="index">
        <div class="image-wrap">
          <img
            :data-src="image"
            class="swiper-lazy product-img"
          >
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white" />
        </div>
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination" />
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'SwiperExamplePaginationFraction',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    banners: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiperOption: {
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/common.scss';
.productslider {
  .image-wrap {
    background: url('~@/assets/images/ready.png');
    width: 100%;
    aspect-ratio: 5 / 3.33;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  .product-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%;
    color: rgba($white-color, $alpha: 1);
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba($sec-color, $alpha: 1),
      0 0 1em rgba($sec-color, $alpha: 1), 0 0 0.2em rgba($sec-color, $alpha: 1);
  }
  .swiper-pagination {
    position: absolute;
    text-align: right;
    padding-right: 20px;
    -webkit-transition: 300ms opacity;
    -o-transition: 300ms opacity;
    transition: 300ms opacity;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }
}
</style>
