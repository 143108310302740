<template>
  <div
    v-if="
      banners &&
        banners['ad_watermark'] &&
        banners['ad_watermark'].content.length !== 0
    "
  >
    <a v-b-modal.modal-promote class="promote-area">
      <img src="~@/assets/images/icon-promote.png" alt="" class="promote-img img-fluid">
    </a>
    <b-modal id="modal-promote" title="" hide-footer @hidden="handlehidden">
      <a :href="randomOne.link">
        <img :src="randomOne.img" alt="" class="img-fluid">
      </a>
    </b-modal>
  </div>
</template>

<script>
import { getBanners } from '@/api/shop'

export default {
  name: 'QuickIcon',
  data() {
    return {
      banners: null,
      randomOne: {}
    }
  },
  async created() {
    try {
      if (!this.$store.getters.banner) {
        await this.getBanner()
      }
      this.banners = this.$store.getters.banner
      if (
        this.banners['ad_watermark'] &&
        this.banners['ad_watermark']['content'].length
      ) {
        this.randomOne = this.getRandom()
      }
    } catch (error) {
      // error
    }
  },
  mounted() {
    // this.showbanner()
  },
  methods: {
    handlehidden() {
      this.$store.commit('SET_SHOW_BANNER', {
        status: true,
        date: new Date().toLocaleDateString()
      })
    },
    showbanner() {
      // 更新BANNER狀態過一天就重製
      if (
        this.$store.getters.showbanner &&
        this.$store.getters.showbanner.status
      ) {
        if (
          new Date().toLocaleDateString() >
          new Date(this.$store.getters.showbanner.date).toLocaleDateString()
        ) {
          this.$store.commit('SET_SHOW_BANNER', {
            status: false,
            date: ''
          })
        }
      }
      if (
        !this.$store.getters.showbanner.status ||
        !this.$store.getters.showbanner.date === ''
      ) {
        const self = this
        setTimeout(function() {
          self.$bvModal.show('modal-promote')
        }, 500)
      }
    },
    getRandom() {
      return this.banners['ad_watermark']['content'][
        Math.floor(
          Math.random() * this.banners['ad_watermark']['content'].length
        )
      ]
    },
    async getBanner() {
      return getBanners().then(response => {
        const bannerObj = {}
        response.data.data.forEach(function(item) {
          bannerObj[item.banner_code] = item
        })
        this.$store.commit('SET_BANNER', bannerObj)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .promote-img {
    width: 40px;
  }
</style>
