<template>
  <div class="inter-wrapper">
    <HeaderInter
      v-if="detail"
      :back-path="'/product/cate/' + detail.category_id"
    />

    <div v-if="detail" class="product-kv">
      <ProductSilder :banners="images" />
      <div class="product-content">
        <h2 class="name">
          <span>{{ detail.name }}</span>
        </h2>

        <div class="d-flex justify-content-between">
          <div class="theme-group">
            <p class="theme">
              {{ cateName }}
            </p>
            <p class="country">
              {{ countryName }} <span v-if="countryName && areaName">·</span>
            </p>
            <p class="city">
              {{ areaName }}
            </p>
          </div>
          <p v-if="detail.min_price === -1" class="price">
            <big>全點支付</big>
            <!-- 最低價 NT$<big>{{ detail.min_price | priceFormat }}</big> -->
          </p>
          <p v-else class="price">
            最低價
            <span v-if="detail.min_price > 0">
              NT$<big>{{ detail.min_price | priceFormat }} </big>
            </span>
            <span v-if="detail.min_price > 0 && detail.point > 0"> + </span>
            <span
              v-if="detail.point > 0"
            ><big> {{ detail.point }}點</big></span>
          </p>
        </div>
        <p v-if="detail.saleoff" class="saleoff">
          {{ detail.saleoff? detail.saleoff : '' }}
        </p>
      </div>
    </div>
    <div v-if="detail" class="producTab-content">
      <div class="inter-wrapper">
        <div class="container">
          <div class="sidebar">
            <fixed-header :threshold="160">
              <ul
                v-scroll-spy-active="{ class: 'customActive' }"
                v-scroll-spy-link
                class="menu"
              >
                <li
                  v-for="(item, index) in titles"
                  :key="index"
                  class="menu-item"
                >
                  <a>{{ item }}</a>
                </li>
              </ul>
            </fixed-header>
          </div>
          <div
            v-scroll-spy="{
              offset: 80,
              time: 5,
              steps: 30,
              sectionSelector: '.item'
            }"
            class="main"
          >
            <!-- 方案 -->
            <div class="menu-list py-3 item">
              <ol>
                <li
                  v-for="(item, index) in viewdetail.plans"
                  :key="index"
                  :class="planClass(item, index)"
                  @click="changePlan(index)"
                >
                  <p class="name">
                    <span>
                      {{ item.plan_name
                      }}{{ item.prices[0].price_name | pricename }}
                    </span>
                    <span v-if="item.refund_type === 2" class="refundtext">
                      <!-- {{ item.policy_cancel }} -->
                      不可取消
                    </span>
                  </p>
                  <p v-if="planHasStock(item)" class="price">
                    <span
                      v-if="
                        planHasStock(item) &&
                          !item.prices[0].points.length &&
                          item.prices[0].origin_price <=
                          item.prices[0].sale_price
                      "
                      class="price-2"
                    >
                      優惠價
                    </span>
                    <span v-else-if="planHasStock(item)" class="originprice">
                      原價NT$ {{ item.prices[0].origin_price }}
                    </span>
                    <span v-if="item.prices[0].points.length > 0">
                      {{ item.prices[0].points[0].point }}點
                      <span
                        v-if="item.prices[0].points[0].sale_price > 0"
                      >+ NT$
                        {{ item.prices[0].points[0].sale_price | priceFormat }}
                      </span>
                    </span>
                    <span v-else-if="item.prices[0].sale_price > 0">
                      NT$ {{ item.prices[0].sale_price | priceFormat }}
                    </span>
                    <span v-else-if="item.prices[0].sale_price === 0">
                      免費
                    </span>
                    <em v-if="!planHasStock(item)">完售</em>
                  </p>
                  <p v-else class="price">
                    <span> 原價NT$ {{ item.prices[0].origin_price }} </span>
                    <em>完售</em>
                  </p>
                </li>
              </ol>
            </div>

            <!-- AD-banner -->
            <div
              v-if="$store.getters.banner && getRandomBanner()"
              class="promote-box"
            >
              <a :href="getRandomBanner().link">
                <img :src="getRandomBanner().img" alt="" class="w-100 img-fluid">
              </a>
            </div>

            <!-- 商品特色 -->
            <div class="py-4 item">
              <dl>
                <dt>商品特色</dt>
                <dd v-for="(item, index) in viewdetail.highlight" :key="index">
                  {{ item }}
                </dd>
              </dl>
            </div>

            <div class="py-3 item">
              <dl v-if="planDesc">
                <dt>
                  方案說明
                </dt>
                <dd class="nl2br">
                  {{ planDesc }}
                </dd>
              </dl>
              <dl v-if="planExpire">
                <dt>方案使用期限</dt>
                <dd>{{ planExpire }}</dd>
              </dl>

              <dl v-if="priceInclude">
                <dt>費用包含</dt>
                <dd>{{ priceInclude }}</dd>
              </dl>

              <dl v-if="priceExclude">
                <dt>費用不包含</dt>
                <dd>{{ priceExclude }}</dd>
              </dl>
              <dl>
                <dt>圖文詳情</dt>
                <dd class="nl2br" v-text="detail.introduction" />
              </dl>
              <dl>
                <dt>使用方法</dt>
                <dd class="nl2br" v-text="useGuide" />
              </dl>
              <dl v-if="notice">
                <dt>票券使用注意事項</dt>
                <dd class="nl2br" v-text="notice" />
              </dl>
              <dl v-if="gettrusttext(viewdetail)">
                <dt>信託/價金文字</dt>
                <dd class="nl2br" v-text="gettrusttext(viewdetail)" />
              </dl>
              <dl v-if="viewdetail.issue_info">
                <dt>發行資訊</dt>
                <dd class="nl2br" v-text="viewdetail.issue_info" />
              </dl>
              <dl
                v-if="viewdetail.plans.length &&
                  (viewdetail.plans[selectedPlan].refund_type === 2 || viewdetail.plans[selectedPlan].policy_cancel)"
              >
                <dt>取消政策</dt>
                <dd class="nl2br">
                  {{ viewdetail.plans[selectedPlan].policy_cancel || '不可取消' }}
                </dd>
              </dl>
            </div>

            <!-- 我是分店 -->
            <div
              v-if="viewdetail.plans[selectedPlan].branches.length"
              class="py-3 item"
            >
              <dl>
                <dt>店家資訊</dt>
                <dd
                  v-for="(item, index) in viewdetail.plans[selectedPlan]
                    .branches"
                  :key="index"
                >
                  ● {{ item.branch_name }} : {{ item.branch_address }}
                  {{ item.branch_phone }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PaymentBtn -->
    <div v-if="detail" class="payment-rule">
      <div v-if="detail.has_stock" class="btn-group">
        <button
          class="btn btn-credit"
          type="button"
          :style="!hasPoint ? 'flex: auto' : ''"
          @click="open('all')"
        >
          付款
        </button>

        <button
          v-if="hasPoint"
          class="btn btn-point"
          type="button"
          @click="open('all')"
        >
          {{ cashPointPay }}
        </button>
      </div>
      <div v-else class="btn-group">
        <button
          class="btn btn-credit no-stock"
          type="button"
          :style="!hasPoint ? 'flex: auto' : ''"
          disabled
        >
          已售完
        </button>
      </div>
      <!-- 純金 方案選項 暫時停用-->
      <vue-bottom-sheet
        v-if="detail.has_stock"
        ref="credit"
        :max-width="maxWidth"
        :max-height="maxHeight"
        :overlay="overlay"
        :click-to-close="clickToClose"
        :effect="effect"
        :rounded="rounded"
      >
        <div class="sheet-content credit">
          <div class="title">
            <span>方案及數量</span>
          </div>

          <div class="prod-group">
            <div class="prod-group-title">
              <span>請選擇方案種類</span>
            </div>
            <div v-for="(plan, planIndex) in detail.plans" :key="planIndex">
              <div
                v-for="(price, pricesIndex) in plan.prices"
                v-show="price.stock > 0"
                :key="pricesIndex"
                :class="[
                  'prod-items',
                  {
                    active:
                      price.price_id ===
                      selectedPrice('price', plan, price, null).price_id
                  }
                ]"
                @click="setItem(plan, price)"
              >
                <span>{{ plan.plan_name }}</span>
                <em>{{ price.price_name }}<br><small>NT${{ price.sale_price | priceFormat }}</small></em>
              </div>
            </div>
          </div>
          <div class="price-group">
            <div class="price-group-title">
              <span>數量與價格</span>
            </div>
            <div class="price-items a">
              <span>單價</span><em>NT${{ creditSalePrice | priceFormat }}</em>
            </div>
            <div class="price-items">
              <span>數量</span>
              <em>
                <div class="number-input">
                  <button @click="incrementDecrementNumber('creditQty', '-')" />
                  <input
                    ref="creditQty"
                    v-model="itemQty"
                    v-validate="'required|integer|min:1|max:' + maxQty"
                    class="quantity"
                    min="1"
                    :max="maxQty"
                    step="1"
                    name="creditQty"
                    data-vv-as="數量"
                    type="number"
                    @change="checkQty()"
                    @keyup="checkQty()"
                  >
                  <button
                    class="plus"
                    @click="incrementDecrementNumber('creditQty', '+')"
                  />
                </div>
              </em>
            </div>
            <div v-if="errors.has('creditQty')" class="price-items">
              <span />
              <small class="help-block">{{ qtyMsg(1, maxQty) }}</small>
            </div>
            <div class="price-items">
              <span>訂單金額</span><em>NT$<big>{{ creditTotalPrice | priceFormat }}</big></em>
            </div>
          </div>

          <div class="btn-group-bottom">
            <button class="btn btn-reset" type="reset" @click="resetItem()">
              清除重寫
            </button>

            <button class="btn btn-next" type="button" @click="doPayment()">
              下一步
            </button>
          </div>
        </div>
      </vue-bottom-sheet>

      <!-- 點加金 方案選項 暫時停用-->
      <!-- <vue-bottom-sheet
        v-if="detail.has_stock && hasPoint"
        ref="point"
        :max-width="maxWidth"
        :max-height="maxHeight"
        :overlay="overlay"
        :click-to-close="clickToClose"
        :effect="effect"
        :rounded="rounded"
      >
        <div class="sheet-content point">
          <div class="title">
            <span>方案及數量</span>
          </div>

          <div class="prod-group">
            <div class="prod-group-title">
              <span>請選擇方案種類</span>
            </div>
            <div v-for="(plan, planIndex) in detail.plans" :key="planIndex">
              <div
                v-for="(price, pricesIndex) in plan.prices"
                v-show="price.stock > 0"
                :key="pricesIndex"
              >
                <div
                  v-for="(point, pointIndex) in price.points"
                  :key="pointIndex"
                  :class="[
                    'prod-items',
                    {
                      active:
                        point.point_id ===
                        selectedPrice('point', plan, price, point).point_id
                    }
                  ]"
                  @click="setItem(plan, price, point)"
                >
                  <span>{{ plan.plan_name }}</span>
                  <em>{{ price.price_name }}<br><small><span class="point">{{ point.point }}</span>點＋NT${{ point.sale_price | priceFormat }}</small></em>
                </div>
              </div>
            </div>
          </div>
          <div class="price-group">
            <div class="price-group-title">
              <span>數量與價格</span>
            </div>
            <div class="price-items a">
              <span>單價</span><em><span class="point">{{ pointSalePoint }}</span>點＋NT${{ pointSalePrice | priceFormat }}</em>
            </div>
            <div class="price-items">
              <span>數量</span>
              <em>
                <div class="number-input">
                  <button @click="incrementDecrementNumber('pointQty', '-')" />
                  <input
                    ref="pointQty"
                    v-model="itemQty"
                    v-validate="'required|integer|min:1|max:' + maxQty"
                    class="quantity"
                    min="1"
                    :max="maxQty"
                    step="1"
                    name="pointQty"
                    type="number"
                    data-vv-as="數量"
                    @change="checkQty()"
                    @keyup="checkQty()"
                  >
                  <button
                    class="plus"
                    @click="incrementDecrementNumber('pointQty', '+')"
                  />
                </div>
              </em>
            </div>
            <div v-if="errors.has('pointQty')" class="price-items">
              <span />
              <small class="help-block">{{ qtyMsg(1, maxQty) }}</small>
            </div>
            <div class="price-items">
              <span>訂單金額</span><em><small><span
                v-if="pointwarning"
                class="pointwarning"
              >點數不足</span></small><big>{{ pointTotalPoint }}</big>點＋NT$<big>{{ pointTotalPrice | priceFormat }}</big></em>
            </div>
          </div>

          <div class="btn-group-bottom">
            <button class="btn btn-reset" type="reset" @click="resetItem()">
              清除重寫
            </button>

            <button class="btn btn-next" type="button" @click="doPayment()">
              下一步
            </button>
          </div>
        </div>
      </vue-bottom-sheet> -->

      <!-- 純金 與 點加金 所有方案選項  2022/1/14 vincent 暫時停用-->
      <!-- <vue-bottom-sheet
        v-if="detail.has_stock"
        ref="all"
        :max-width="maxWidth"
        :max-height="maxHeight"
        :overlay="overlay"
        :click-to-close="clickToClose"
        :effect="effect"
        :rounded="rounded"
      >
        <div class="sheet-content all point">
          <div class="title">
            <span>方案及數量</span>
          </div>

          <div class="prod-group">
            <div class="prod-group-title">
              <span>請選擇方案種類</span>
            </div>
            <div v-for="(plan, planIndex) in detail.plans" :key="planIndex">
              <div
                v-for="(price, pricesIndex) in plan.prices"
                v-show="price.stock > 0"
                :key="pricesIndex"
              >
                <div
                  ref="proditems"
                  :class="[
                    'prod-items',
                    {
                      active:
                        price.price_id ===
                        selectedPrice('price', plan, price, null).price_id &&
                        !selectedPrice('price', plan, price, null).point
                    }
                  ]"
                  @click="setItem(plan, price)"
                >
                  <span>{{ plan.plan_name }}</span>
                  <em>{{ price.price_name }}<br><small>NT${{ price.sale_price | priceFormat }}</small></em>
                </div>
                <div
                  v-for="(point, pointIndex) in price.points"
                  ref="proditems"
                  :key="pointIndex"
                  :class="[
                    'prod-items',
                    {
                      active:
                        point.point_id ===
                        selectedPrice('point', plan, price, point).point_id &&
                        selectedPrice('point', plan, price, null).point
                    }
                  ]"
                  @click="setItem(plan, price, point)"
                >
                  <span>{{ plan.plan_name }}</span>
                  <em>{{ price.price_name }}<br><small><span class="point">{{ point.point }}</span>點＋NT${{ point.sale_price | priceFormat }}</small></em>
                </div>
              </div>
            </div>
          </div>
          <div class="price-group">
            <div class="price-group-title">
              <span>數量與價格</span>
            </div>
            <div class="price-items a">
              <span>單價</span><em><span class="point">{{ pointSalePoint }}</span>點＋NT${{ pointSalePrice | priceFormat }}</em>
            </div>
            <div class="price-items">
              <span>數量</span>
              <em>
                <div class="number-input">
                  <button @click="incrementDecrementNumber('pointQty', '-')" />
                  <input
                    ref="pointQty"
                    v-model="itemQty"
                    v-validate="'required|integer|min:1|max:' + maxQty"
                    class="quantity"
                    min="1"
                    :max="maxQty"
                    step="1"
                    name="pointQty"
                    type="number"
                    data-vv-as="數量"
                    @change="checkQty()"
                    @keyup="checkQty()"
                  >
                  <button
                    class="plus"
                    @click="incrementDecrementNumber('pointQty', '+')"
                  />
                </div>
              </em>
            </div>
            <div v-if="errors.has('pointQty')" class="price-items">
              <span />
              <small class="help-block">{{ qtyMsg(1, maxQty) }}</small>
            </div>
            <div class="price-items">
              <span>訂單金額</span><em><small><span
                v-if="pointwarning"
                class="pointwarning"
              >點數不足</span></small><big>{{ pointTotalPoint }}</big>點＋NT$<big>{{ pointTotalPrice | priceFormat }}</big></em>
            </div>
          </div>

          <div class="btn-group-bottom">
            <button class="btn btn-reset" type="reset" @click="resetItem()">
              清除重寫
            </button>

            <button class="btn btn-next" type="button" @click="doPayment()">
              下一步
            </button>
          </div>
        </div>
      </vue-bottom-sheet> -->

      <!-- 純金 與 點加金 所有方案選項  2023/2/1 vincent component裡面參考vue-bottom-sheet plugin-->
      <!-- 這邊有改最大最小購買數，上面兩個未改 2023/3/24 vincent-->
      <BottomSheet
        ref="all"
        :max-width="maxWidth"
        :max-height="maxHeight"
        :overlay="overlay"
        :click-to-close="clickToClose"
        :rounded="rounded"
        :effect="effect"
        :background-scrollable="false"
      >
        <template #title>
          <div class="sheet-content all" style="padding:8px;">
            <div class="title" style="position:relative;">
              <span>方案及數量</span>
              <div
                style="position: absolute;right: 5px;top: 0px;cursor: pointer;"
                @click="close('all')"
              >
                &#215;
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="sheet-content all point">
            <div class="prod-group">
              <div class="prod-group-title">
                <span>請選擇方案種類</span>
              </div>
              <div v-for="(plan, planIndex) in detail.plans" :key="planIndex">
                <div
                  v-for="(price, pricesIndex) in plan.prices"
                  v-show="price.stock > 0"
                  :key="pricesIndex"
                >
                  <div
                    ref="proditems"
                    :class="[
                      'prod-items',
                      {
                        active:
                          price.price_id ===
                          selectedPrice('price', plan, price, null)
                            .price_id &&
                          !selectedPrice('price', plan, price, null).point
                      }
                    ]"
                    @click="setItem(plan, price)"
                  >
                    <span>{{ plan.plan_name }}</span>
                    <em>{{ price.price_name }}<br><small>NT${{ price.sale_price | priceFormat }}</small></em>
                  </div>
                  <div
                    v-for="(point, pointIndex) in price.points"
                    ref="proditems"
                    :key="pointIndex"
                    :class="[
                      'prod-items',
                      {
                        active:
                          point.point_id ===
                          selectedPrice('point', plan, price, point)
                            .point_id &&
                          selectedPrice('point', plan, price, null).point
                      }
                    ]"
                    @click="setItem(plan, price, point)"
                  >
                    <span>{{ plan.plan_name }}</span>
                    <em>{{ price.price_name }}<br><small><span class="point">{{ point.point }}</span>點＋NT${{ point.sale_price | priceFormat }}</small></em>
                  </div>
                </div>
              </div>
            </div>
            <div class="price-group">
              <div class="price-group-title">
                <span>數量與價格</span>
              </div>
              <div class="price-items a">
                <span>單價</span>
                <em>
                  <template v-if="pointSalePoint !== '-'">
                    <span class="point">{{ pointSalePoint }}</span>點
                  </template>
                  ＋NT${{ pointSalePrice | priceFormat }}
                </em>
              </div>
              <div class="price-items">
                <span>數量</span>
                <em>
                  <div class="number-input">
                    <button
                      @click="incrementDecrementNumber('pointQty', '-')"
                    />
                    <input
                      ref="pointQty"
                      v-model="itemQty"
                      v-validate="'required|integer|min:1|max:' + maxQty + '|min_value:' + minQty"
                      class="quantity"
                      :min="minQty"
                      :max="maxQty"
                      step="1"
                      name="pointQty"
                      type="number"
                      data-vv-as="數量"
                      @change="checkQty()"
                      @keyup="checkQty()"
                    >
                    <button
                      class="plus"
                      @click="incrementDecrementNumber('pointQty', '+')"
                    />
                  </div>
                </em>
              </div>
              <div v-if="errors.has('pointQty')" class="price-items">
                <span />
                <small class="help-block">{{ qtyMsg(minQty, maxQty) }}</small>
              </div>
              <div class="price-items">
                <span>訂單金額</span>
                <em>
                  <small><span v-if="pointwarning" class="pointwarning">點數不足</span></small>
                  <template v-if="pointTotalPoint !== '-'">
                    <big>{{ pointTotalPoint }}</big>點
                  </template>
                  ＋NT$<big>{{ pointTotalPrice | priceFormat }}</big>
                </em>
              </div>
            </div>
            <div class="btn-group-bottom">
              <button class="btn btn-reset" type="reset" @click="resetItem()">
                清除重寫
              </button>

              <button class="btn btn-next" type="button" @click="doPayment()">
                下一步
              </button>
            </div>
          </div>
        </template>
      </BottomSheet>
    </div>
    <QuickIcon />
  </div>
</template>

<script>
import Vue from 'vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import HeaderInter from '@/components/HeaderInter'
import ProductSilder from '@/components/ProductSilder'
import BottomSheet from '@/components/BottomSheet'
import { getItem } from '@/api/shop'
import { showNotice, getStoreCode, getTitle } from '@/utils/tool'
import { pointcheck } from '@/utils/user'
import scrollSpy from 'vue2-scrollspy'
import FixedHeader from 'vue-fixed-header'
import QuickIcon from '@/components/QuickIcon'
Vue.use(scrollSpy)

export default {
  name: 'ProductDetail',
  components: {
    HeaderInter,
    ProductSilder,
    VueBottomSheet,
    FixedHeader,
    QuickIcon,
    BottomSheet
  },
  data() {
    return {
      pointwarning: false,
      viewdetail: false,
      detail: false,
      titles: ['方案', '特色', '權益', '分店'],
      // section: 0,
      fixed: false,
      overlay: true,
      maxWidth: '640px',
      maxHeight: '80%',
      clickToClose: true,
      effect: 'fx-default',
      rounded: true,
      maxQty: 1,
      minQty: 1,
      // 程式用
      selectedPlan: 0,
      selectedTarget: null,
      itemQty: this.$store.getters.orderItem.plans[0].prices[0].qty,
      randomBanner: null
    }
  },
  computed: {
    images: function() {
      if (this.detail.images.length > 0) {
        return this.detail.images
      } else {
        return [this.detail.cover_image]
      }
    },
    hasPoint: function() {
      return this.detail.has_points
    },
    cateName: function() {
      return this.detail.category_name
    },
    countryName: function() {
      return this.detail.areas[0] !== undefined ? this.detail.areas[0].name : ''
    },
    areaName: function() {
      return this.detail.areas[1] !== undefined ? this.detail.areas[1].name : ''
    },
    priceInclude: function() {
      return this.viewdetail.plans[this.selectedPlan].inclusion
    },
    priceExclude: function() {
      return this.viewdetail.plans[this.selectedPlan].exclusion
    },
    useGuide: function() {
      return this.viewdetail.plans[this.selectedPlan].guide
    },
    notice: function() {
      return this.viewdetail.plans[this.selectedPlan].notice
    },
    planDesc: function() {
      return this.viewdetail.plans[this.selectedPlan].description
    },
    planExpire: function() {
      if (!this.viewdetail.plans[this.selectedPlan].prices.length) return ''
      return this.viewdetail.plans[this.selectedPlan].prices[0].usedate_remark
    },
    creditSalePrice: function() {
      return this.selectedPrice().sale_price
        ? this.selectedPrice().sale_price
        : 0
    },
    creditTotalPrice: function() {
      return this.selectedPrice().sale_price
        ? this.selectedPrice().sale_price * this.selectedPrice().qty
        : 0
    },
    pointSalePoint: function() {
      return this.selectedPrice().point ? this.selectedPrice().point : '-'
    },
    pointSalePrice: function() {
      return this.selectedPrice().sale_price
        ? this.selectedPrice().sale_price
        : 0
    },
    pointTotalPoint: function() {
      return this.selectedPrice().point
        ? this.selectedPrice().point * this.selectedPrice().qty
        : '-'
    },
    pointTotalPrice: function() {
      return this.selectedPrice().sale_price
        ? this.selectedPrice().sale_price * this.selectedPrice().qty
        : 0
    },
    selectedPrice() {
      return function(type, planInfo, priceInfo, pointInfo) {
        const selected = this.$store.getters.orderItem.plans[0].prices[0]
        return selected
      }
    },
    qtyMsg() {
      return function(min, max) {
        return '數量需大於' + min + '並小於' + max + '間的整數'
      }
    },
    cashPointPay() {
      return getStoreCode() === 'happygo' ? 'HAPPYGO 點數優惠' : '點數折抵'
    }
  },
  watch: {
    itemQty: function(val) {
      const item = this.$store.getters.orderItem
      item.plans[0].prices[0].qty = val
      const pcheck = pointcheck(
        parseInt(item.plans[0].prices[0].qty) *
          parseInt(item.plans[0].prices[0].point)
      )
      if (pcheck.needcheck && !pcheck.enough) {
        this.pointwarning = true
      } else {
        this.pointwarning = false
      }
      this.$store.commit('SET_ITEM', item)
    }
  },
  created() {
    getItem(this.$route.params.id).then(response => {
      // 商品頁 Title
      if (response.data.data.length === 0) {
        const self = this
        getTitle()
        showNotice('查無商品資訊', function() {
          self.$router.push('/product')
        })
      } else {
        getTitle(response.data.data.name)
        if (this.$route.params.cate) {
          response.data.data.category_id = this.$route.params.cate
        }
        this.detail = response.data.data
        this.viewdetail = this.initviewdataformat(response.data.data)
        // 若沒有店家資訊, 移除分頁的那個選項
        if (
          this.viewdetail.plans.length &&
          !this.viewdetail.plans[this.selectedPlan].branches.length
        ) {
          this.titles.splice(3, 1)
        }
        if (this.detail.item_id !== this.$store.getters.orderItem.item_id) {
          // 購物車跟本頁商品不符
          this.resetItem()
        }
      }
    })
  },
  methods: {
    initviewdataformat(data) {
      const finalarray = JSON.parse(JSON.stringify(data))
      finalarray.plans = []
      data.plans.forEach(element => {
        if (element.prices.length) {
          element.prices.forEach(e1 => {
            // 有票卷種類 篩選出純金的方案
            const _tmp = JSON.parse(JSON.stringify(element))
            const _tmp_prices = JSON.parse(JSON.stringify(e1))
            _tmp_prices.points = []
            _tmp.prices = []
            _tmp.prices.push(_tmp_prices)
            finalarray.plans.push(_tmp)
            // 假設有點數且有庫存
            if (e1.points.length && e1.stock !== 0) {
              // 篩選出不同種類的點加金方案
              e1.points.forEach((e, i, arr) => {
                const tmp_p = JSON.parse(JSON.stringify(_tmp))
                tmp_p.prices[0].points = []
                tmp_p.prices[0].points.push(e1.points[i])
                finalarray.plans.push(tmp_p)
              })
            }
          })
        }
      })
      return finalarray
    },
    getRandomBanner() {
      if (!this.randomBanner) {
        const banners = this.$store.getters.banner
        if (banners['ad_item_detail']) {
          if (banners['ad_item_detail']['content'].length === 0) {
            this.randomBanner = null
          } else {
            this.randomBanner =
              banners['ad_item_detail']['content'][
                Math.floor(
                  Math.random() * banners['ad_item_detail']['content'].length
                )
              ]
          }
        }
      }
      return this.randomBanner
    },
    changePlan(index) {
      this.selectedPlan = index
    },
    isSelectedPrices(target) {
      const res = []
      this.detail.plans.map(plan => {
        plan.prices.map(price => {
          if (parseInt(price.stock) > 0) {
            if (target === 'all') {
              res.push({
                plan: plan,
                price: price,
                point: null
              })
              if (price.points.length) {
                price.points.forEach(point => {
                  res.push({
                    plan: plan,
                    price: price,
                    point: point
                  })
                })
              }
            } else if (target === 'point') {
              price.points.map(point => {
                res.push({
                  plan: plan,
                  price: price,
                  point: point
                })
              })
            } else {
              res.push({
                plan: plan,
                price: price,
                point: null
              })
            }
          }
        })
      })
      return res.length === 1 ? res : false
    },
    open(target) {
      if (
        this.$store.getters.orderItem.plans[0].prices[0].point_id &&
        target !== 'point'
      ) {
        this.resetItem()
      } else if (
        !this.$store.getters.orderItem.plans[0].prices[0].point_id &&
        target !== 'credit'
      ) {
        this.resetItem()
      }
      const res = this.isSelectedPrices(target)
      if (res !== false) {
        this.setItem(res[0].plan, res[0].price, res[0].point)
      }
      // 選擇付款跟方案清單的相同選項 click對應的選項 2022/1/14
      if (target === 'all') {
        this.$refs.proditems[this.selectedPlan].click()
      }
      this.selectedTarget = target
      this.$refs[target].open()
    },
    close(target) {
      this.$refs[target].close()
    },
    incrementDecrementNumber(ref, action) {
      switch (action) {
        case '+':
          this.$refs[ref].stepUp()
          break
        case '-':
          this.$refs[ref].stepDown()
          break
      }
      this.itemQty = this.$refs[ref].value
      this.checkQty()
    },
    checkQty() {
      if (!this.$store.getters.orderItem.item_id) {
        return showNotice('請正確選擇方案、數量')
      }
      if (this.maxQty < this.itemQty) {
        const self = this
        showNotice('此項目最大數量 ' + this.maxQty, function() {
          self.itemQty = self.maxQty
        })
      }
    },
    planClass(plan, index) {
      if (plan.prices[0].stock < 1) {
        return 'soldout'
      } else {
        if (this.selectedPlan === index) return 'active'
        else return ''
      }
    },
    planHasStock(plan) {
      return plan.prices[0].stock >= 1
    },
    doPayment() {
      if (this.$store.getters.orderItem.plans[0].prices[0].point) {
        const pcheck = pointcheck(
          parseInt(this.$store.getters.orderItem.plans[0].prices[0].qty) *
            parseInt(this.$store.getters.orderItem.plans[0].prices[0].point)
        )
        if (pcheck.needcheck && !pcheck.enough) {
          this.pointwarning = true
          showNotice('點數不足, 請確認你的點數!!')
          return false
        }
      }
      if (!this.$store.getters.orderItem.item_id) {
        showNotice('請正確選擇方案、數量')
      } else {
        this.$validator.validateAll().then(valid => {
          if (valid) {
            this.close(this.selectedTarget)
            this.$router.push('/order/init')
          }
        })
      }
    },
    resetItem() {
      this.$store.commit('SET_DEFAULT_ITEM')
      this.itemQty = 1
      this.maxQty = 1
      this.pointwarning = false
    },
    setItem(planInfo, priceInfo, pointInfo) {
      this.itemQty = 1
      this.maxQty = priceInfo.stock
      let salePrice = priceInfo.sale_price
      let pointId = null
      let point = null
      if (pointInfo && pointInfo !== undefined) {
        pointId = pointInfo.point_id
        point = pointInfo.point
        salePrice = pointInfo.sale_price
        const pcheck = pointcheck(pointInfo.point)
        if (pcheck.needcheck && !pcheck.enough) {
          this.pointwarning = true
        } else {
          this.pointwarning = false
        }
      } else {
        // 不是點數都不要紅字提示
        this.pointwarning = false
      }
      // 選東西限制各自的最大最小數量
      if (parseInt(priceInfo.quantity_max) > 0 && priceInfo.stock > parseInt(priceInfo.quantity_max)) {
        this.maxQty = parseInt(priceInfo.quantity_max)
      }
      if (parseInt(priceInfo.quantity_min) >= 0) {
        this.minQty = parseInt(priceInfo.quantity_min) === 0 ? 1 : parseInt(priceInfo.quantity_min)
        // 當最小限制數量超過1，數量開始值為最小限制數量
        this.itemQty = parseInt(priceInfo.quantity_min) === 0 ? 1 : parseInt(priceInfo.quantity_min)
      }
      this.$store.commit('SET_ITEM', {
        item_id: this.detail.item_id,
        plans: [
          {
            plan_id: planInfo.plan_id,
            prices: [
              {
                price_id: priceInfo.price_id,
                sale_price: salePrice,
                qty: this.itemQty,
                point: point,
                point_id: pointId,
                minQty: this.minQty,
                maxQty: this.maxQty
              }
            ]
          }
        ]
      })
    },
    gettrusttext(info) {
      const trusttext = this.viewdetail.plans[this.selectedPlan].trusttext
        ? this.viewdetail.plans[this.selectedPlan].trusttext
        : this.viewdetail.trusttext
      return trusttext
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/common.scss';
.product-kv {
  margin-top: 44px;
}
.originprice {
  text-decoration: line-through;
  color: rgba($sec-color, $alpha: 0.4);
}
.theme-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  .theme {
    background: rgba($eating_item, $alpha: 0.85);
    color: rgba($white_color, $alpha: 0.85);
    border-radius: 50px;
    padding: 2px 10px;
    margin-right: 6px;
  }
  p {
    font-size: 1rem;
    margin: 0px;
    padding: 0;
  }
}

.product-content {
  margin: 0;
  padding: 15px;
  position: relative;
  border-radius: 5px;
  z-index: 3;
  .name {
    font-size: 1.25rem;
    font-weight: bolder;
    margin: 0 0 0.5rem;
    padding: 0;
    span {
      color: $main-color;
      margin-right: 15px;
    }
  }
  .price {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    color: $price-color;
    big {
      font-weight: bolder !important;
    }
  }

  .saleoff {
    margin: 0;
    text-align: right;
    font-size: 1.25rem;
    color: $price-color;
  }
}
// ProductTab
.sidebar {
  position: relative;
  z-index: 100;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.menu {
  display: flex;
  flex-direction: row;
}
.menu-item {
  flex: 1 1 auto;
  text-align: center;
  background: $white-color;
  border-bottom: 1px solid rgba($sec-color, $alpha: 0.2);
  box-shadow: 0px 10px 10px 0px rgba($main-color, $alpha: 0.1);
}

.menu-item a {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
  padding: 10px 0;
}
.customActive {
  color: #fff;
  transition: all 0.5s;
  background: $main-color;
  border-radius: 0;
}

.promote-box img {
  border-radius: 4px;
}

.current-section {
  padding-top: 100px;
}
.main {
  padding-bottom: 7rem;
  p {
    margin: 0 0 0.5rem;
    padding: 0;
  }
  dl {
    padding-bottom: 1rem;
  }
  dt {
    color: $main-color;
    font-size: 1.15rem;
    margin-bottom: 0.35rem;
  }
  dd {
    font-size: 1rem;
    line-height: 1.85rem;
    margin: 0;
    padding: 0;
  }
  dd span {
    color: $sec-color;
  }
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    line-height: 1.85rem;
    .price {
      color: $price-color;
      font-weight: bolder !important;
    }
  }
  .menu-list {
    li {
      background: $assist-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 0 0.65rem;
      padding: 0.85rem;
      line-height: 1rem;
      border-radius: 4px;
      .name {
        span {
          vertical-align: middle;
        }
        .refundtext {
          display: inline;
          line-height: 1;
          background-color: $price-color;
          color: white;
          font-weight: 700;
          border-radius: 4px;
          font-size: 12px;
          padding: 3px 8px;
          white-space: nowrap;
        }
      }
      .price {
        color: $price-color;
        line-height: 1rem;
        margin: 0;
        font-weight: bolder !important;
        display: flex;
        justify-content: space-between;
        em {
          font-style: normal;
        }
      }
    }
    li.soldout {
      color: rgba($black-color, $alpha: 0.35);
      .price span {
        color: rgba($black-color, $alpha: 0.35);
      }
    }
  }
}

.menu.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
}
@media screen and (min-width: 640px) {
  .menu.vue-fixed-header--isFixed {
    margin: auto;
    max-width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .menu.vue-fixed-header--isFixed {
    max-width: 768px;
  }
}

//PaymentBtn
.sheet-content {
  height: 100%;
  // overflow: scroll;
  padding: 20px 10px;
  margin: 0 15px;
  .title {
    text-align: center;
    font-size: 1.15rem;
    font-weight: bolder;
    color: rgba($main-color, $alpha: 1);
    // 拿掉不要線條改關閉按鈕
    // background: -webkit-gradient(
    //   linear,
    //   0 0,
    //   0 100%,
    //   color-stop(0.5, #000),
    //   color-stop(0.5, transparent),
    //   to(transparent)
    // );
    // background-repeat: repeat-x;
    // background-position: center center;
    // -webkit-background-size: 2px;
    display: block;
    margin: 0 auto 5px;
    span {
      padding: 0 35px;
      background-color: $white-color;
    }
  }
}

@media screen and (min-width: 768px) {
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm),
    screen and(-webkit-min-device-pixel-ratio:0) {
    .sheet-content {
      margin: 0 auto;
      height: 55vh;
      overflow: scroll;
      padding: 10px 0px 10px 30px;
    }
  }
}

.prod-group,
.price-group {
  margin-bottom: 1.5rem;
  .prod-group-title,
  .price-group-title {
    border-left: 5px solid $main-color;
    margin-bottom: 1rem;
    span {
      padding-left: 5px;
    }
  }
}
.prod-items {
  border: 1px solid $main-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 15px;
  margin: 0 0 5px;
  color: $sec-color;
  span,
  em {
    color: $font-color;
    font-size: 1rem;
    line-height: 1.15rem;
  }
  em {
    font-style: normal;
    text-align: right;
    .point {
      color: rgba($font-color, $alpha: 1);
      padding: 0 2px;
    }
  }
}

// .prod-items:hover,
// .prod-items:focus,
// .prod-items:active,
.prod-items.active {
  background: $main-color;
  color: $white-color;
  span,
  em {
    color: $white-color;
  }
}

.price-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  margin: 0px;
  color: rgba($font-color, $alpha: 1);
  line-height: 1.15rem;
  span {
    color: $main-color;
  }
  em {
    font-style: normal;
    .pointwarning {
      font-weight: bold;
      color: $warning-color;
      margin-right: 0.5rem;
    }
    .point {
      font-weight: bolder;
      color: rgba($font-color, $alpha: 1);
      padding: 0 3px;
    }
    big {
      color: $notice-color;
      font-weight: bolder;
      font-size: 1.5rem;
      font-family: 'Roboto', sans-serif;
      vertical-align: middle;
      top: -2px;
      position: relative;
    }
  }

  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .number-input {
    border: 1px solid $main-color;
    display: inline-flex;
  }

  .number-input,
  .number-input * {
    box-sizing: border-box;
  }

  .number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    margin: 0;
    position: relative;
  }

  .number-input button:before,
  .number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    width: 0.7rem;
    height: 2px;
    background-color: $sec-color;
    transform: translate(-50%, -50%);
  }
  .number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  .number-input input[type='number'] {
    font-family: sans-serif;
    max-width: 10rem;
    padding: 0.8rem;
    border: solid $main-color;
    border-width: 0 2px;
    font-size: 1rem;
    height: 2.5rem;
    font-weight: bold;
    text-align: center;
    border-radius: 0 !important;
  }
}

.price-items:nth-child(2) {
  margin-bottom: 3px;
}

.sheet-content.credit {
  .title {
    color: rgba($notice-color, $alpha: 1);
  }
  .prod-group,
  .price-group {
    .prod-group-title,
    .price-group-title {
      border-left: 5px solid $notice-color;
    }
  }
  .prod-items {
    border: 1px solid $notice-color;
    span,
    em {
      color: $font-color;
    }
  }
  .prod-items:hover,
  .prod-items:focus,
  .prod-items:active,
  .prod-items.active {
    background: $notice-color;
    span,
    em {
      color: rgba($white-color, $alpha: 1);
    }
  }

  .price-items {
    span {
      color: $font-color;
    }
    .number-input {
      border: 1px solid $notice-color;
    }
    .number-input button:before,
    .number-input button:after {
      background-color: $sec-color;
    }
    .number-input input[type='number'] {
      border: solid $notice-color;
      border-width: 0 2px;
    }
  }
}
</style>
